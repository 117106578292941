import fastdom from 'fastdom'
import fastdomPromised from 'fastdom/extensions/fastdom-promised'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const fd = fastdom.extend(fastdomPromised)

const navigation = {
  container: document.querySelector('.globalNavigation__content'),
  overlay: document.querySelector('.navigationOverlay'),
  toggle: undefined,
  open: false,
  scrollPos: 0,

  init: function () {
    this.toggle = document.querySelector('.js-toggle')
    this.toggle.addEventListener(
      'click',
      this.toggleNavigation.bind(this),
      false,
    )
    this.overlay.addEventListener(
      'click',
      this.closeNavigation.bind(this),
      false,
    )
  },

  openNavigation: async function () {
    if (!this.open) {
      fd.mutate(() => {
        disableBodyScroll(this.container)
        document.body.classList.add('is-open')
      })

      this.open = true
    }
  },

  closeNavigation: function () {
    if (this.open) {
      fd.mutate(() => {
        enableBodyScroll(this.container)
        document.body.classList.remove('is-open')
      })

      this.open = false
    }
  },

  toggleNavigation: function () {
    if (this.open) {
      this.closeNavigation()
    } else {
      this.openNavigation()
    }
  },
}

export default navigation
