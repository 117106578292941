import fastdom from 'fastdom'

const lazyload = (page) => {
  const images = page.querySelectorAll('img[data-lazy]')
  const videos = page.querySelectorAll('video[data-lazy]')

  if (images.length > 0) {
    if (!('srcset' in images[0])) {
      images.forEach((image) => {
        image.src = image.dataset.src
      })
    } else {
      const loadImage = (image, width) => {
        fastdom.mutate(() => {
          image.setAttribute('srcset', image.dataset.srcset)
          image.setAttribute('sizes', `${width}px`)
        })

        image.addEventListener(
          'load',
          () => {
            fastdom.mutate(() => {
              image.setAttribute('data-loaded', 'true')
            })
          },
          false
        )
      }

      const observer = new IntersectionObserver(
        (images) => {
          images.forEach((image) => {
            if (image.intersectionRatio > 0) {
              observer.unobserve(image.target)
              loadImage(image.target, image.boundingClientRect.width)
            }
          })
        },
        {
          rootMargin: '100px',
        }
      )

      images.forEach((image) => {
        observer.observe(image)
      })
    }
  }

  if (videos.length > 0) {
    videos.forEach((video) => {
      const intersectionHandler = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            fastdom.mutate(() => {
              entry.target.src = entry.target.getAttribute('data-src')
            })
            observer.unobserve(entry.target)
          }
        })
      }

      const observer = new IntersectionObserver(intersectionHandler, {
        rootMargin: '500px 0px'
      })

      observer.observe(video)
    })
  }
}

export default lazyload
