const loadModules = (el) => {
  const page = {
    page: el,
    queue: [],

    setup: function () {
      this.videoCover =
        this.page.querySelector('[data-module="video-cover"]') || undefined

      this.videoEl =
        this.page.querySelector('[data-module="video"]') || undefined

      this.listFilter =
        this.page.querySelector('[data-module="filter"]') || undefined

      this.newsletter =
        this.page.querySelector('[data-module="newsletter"]') || undefined

      this.program = this.page.querySelector('#mount') || undefined

      this.videoEmbeds =
        this.page.querySelectorAll('[data-module="video-embed"]') || undefined

      if (this.videoCover !== undefined) {
        import(
          /* webpackChunkName: "video-cover" */ '../modules/videoCover'
        ).then(({ default: setupVideoCover }) =>
          this.queue.push(setupVideoCover(this.videoCover).init())
        )
      }

      if (this.videoEl !== undefined) {
        import(/* webpackChunkName: "video" */ '../modules/video').then(
          ({ default: setupVideo }) => {
            this.queue.push(setupVideo(this.videoEl).init())
          }
        )
      }

      if (this.listFilter !== undefined) {
        import(/* webpackChunkName: "filter" */ '../modules/listFilter').then(
          ({ default: setupFilter }) => {
            this.queue.push(setupFilter(this.listFilter).init())
          }
        )
      }

      if (this.newsletter !== undefined) {
        import(
          /* webpackChunkName: "newsletter" */ '../modules/newsletter'
        ).then(({ default: setupNewsletter }) => {
          this.queue.push(setupNewsletter(this.newsletter).init())
        })
      }

      if (this.program !== undefined) {
        import(/* webpackChunkName: "program" */ '../../vue/index')
      }

      if (this.videoEmbeds !== undefined) {
        import(
          /* webpackChunkName: "video-embed" */ '../modules/videoEmbed'
        ).then(({ default: setupVideoEmbed }) => {
          Array.from(this.videoEmbeds).forEach((el) => {
            setupVideoEmbed(el).init()
          })
        })
      }
    },

    destroy: function () {},

    init: function () {
      this.setup()
      return Promise.all(this.queue)
    },
  }

  return page
}

export default loadModules
