export function throttle(cb, interval = 100) {
  let enable = true

  return function (...args) {
    if (!enable) {
      return
    }

    enable = false
    cb.apply(this, args)
    setTimeout(() => {
      enable = true
    }, interval)
  }
}

export function debounce(cb, interval = 100) {
  let id

  return function (...args) {
    clearTimeout(id)
    id = setTimeout(() => {
      cb.apply(this, args)
    }, interval)
  }
}

export function clamp(val, min, max) {
  return Math.min(Math.max(val, min), max)
}

export function normalize(val, min, max, opts) {
  let num = val

  if (opts.clamp) {
    num = clamp(num, min, max)
  }

  const normalized = (num - min) / (max - min)

  return opts.reverse ? 1 - normalized : normalized
}
