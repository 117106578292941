import './globals/polyfills'
import fastdom from 'fastdom'
import fastdomPromised from 'fastdom/extensions/fastdom-promised'
import loadModules from './globals/loadModules'
import navigation from './modules/navigation'
import lazyload from './globals/lazyload'
import scrollListener from './globals/scrollListener'

const fd = fastdom.extend(fastdomPromised)

const page = {
  el: document.body,
  trigger: document.querySelector('.scroll-trigger'),

  init: function () {
    navigation.init()
    scrollListener.init()
    lazyload(this.el)

    return loadModules(document.querySelector('.main')).init()
  },
}

window.addEventListener(
  'load',
  async () => {
    await page.init()

    fd.mutate(() => {
      document.body.classList.add('is-loaded')
    })
  },
  false
)

if (module.hot) {
  module.hot.accept()
}
