import fastdom from 'fastdom'
import fastdomPromised from 'fastdom/extensions/fastdom-promised'
import { normalize } from '../utils'

const fd = fastdom.extend(fastdomPromised)

const scrollListener = {
  el: document.body,
  scrollTrigger: document.querySelector('.scroll-trigger'),
  footerTrigger: document.querySelector('.footer-trigger'),
  scrollObserver: undefined,
  footerObserver: undefined,

  scrollHandler: function (entries) {
    entries.forEach((entry) => {
      const distance = entry.boundingClientRect.top
      const max = entry.rootBounds.height
      const dist = Math.min(
        4 *
          normalize(distance, 0, max, {
            clamp: true,
            reverse: true,
          }),
        1
      )

      if (dist > 0.2) {
        fd.mutate(() => {
          this.el.classList.add('is-scrolled')
        })
      } else {
        fd.mutate(() => {
          this.el.classList.remove('is-scrolled')
        })
      }

      fd.mutate(() => {
        this.el.style.setProperty('--scroll-dist', dist)
      })
    })
  },

  buildThresholdList: function () {
    let steps = []

    for (let i = 0; i <= 1000; i++) {
      steps.push(i / 1000)
    }

    return steps
  },

  footerHandler: function (entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        fd.mutate(() => {
          this.el.classList.add('is-bottom')
        })
      } else {
        fd.mutate(() => {
          this.el.classList.remove('is-bottom')
        })
      }
    })
  },

  addIntersectionObservers: function () {
    this.scrollObserver = new IntersectionObserver(
      this.scrollHandler.bind(this),
      {
        threshold: this.threshold,
      }
    )

    this.scrollObserver.observe(this.scrollTrigger)

    this.footerObserver = new IntersectionObserver(
      this.footerHandler.bind(this)
    )

    this.footerObserver.observe(this.footerTrigger)
  },

  init: function () {
    this.threshold = this.buildThresholdList()
    this.addIntersectionObservers()
  },
}

export default scrollListener
